// THIS COMPONENT IS USED FOR LOGIN REGISTRATION AND LOGOUT
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

// THIS IS THE HANDLER FUNCTION WITH HANDLE DIFFERENT DISPATCH EVENTS
const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  // THIS FUNCTION IS USED TO RETURN STATE USING REACT-REDUX
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  // THIS FUNCTION IS USED FOR LOGOUT
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  // THIS FUNCTION IS USED TO REGISTRATION
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);


const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/api/account/my-account');
          const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  // THIS IS LOGIN API
  const login = async (email, password) => {
    const response = await fetch(`https://api.freightsnap.com/smallpackage_webservices/login.php?email=${email}&password=${password}`,
    )
    let user;
    await response.json().then((e) => {
      user = e;
    })
    const fname = user.fname;
    const lname = user.lname;
    const emailID = user.user_id;
    const scanned_terminal_id = user.scanned_terminal_id;
    const company_id = user.company_id;
    localStorage.setItem("fname", fname);
    localStorage.setItem("lname", lname);
    localStorage.setItem("emailID", emailID);
    localStorage.setItem("scanned_terminal_id", scanned_terminal_id);
    localStorage.setItem("company_id", company_id);
    const id = user.id;
    localStorage.setItem('id', id);
    if (user.length != 0) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    }
    return user;
  };

  // THIS IS REGISTRATION API
  const register = async (firstname, lastname, email, zipcode, password) => {
    const response = await fetch(`https://api.freightsnap.com/smallpackage_webservices/addUser_online.php?firstname=${firstname}&lastname=${lastname}&email=${email}&passsword=${password}&zipcode=${zipcode}`,
      // const response = await fetch(`https://api.freightsnap.com/smallpackage_webservices/addUser_online.php?firstname=${firstname}&lastname=${lastname}&email=${email}&passsword=${password}&zipcode=${zipcode}`,
    )
    let user;
    await response.json().then((e) => {
      user = e;
      if (user.toString().length > 10) {
        // dispatch({
        //   type: 'REGISTER',
        //   payload: {
        //     user: {
        //       firstname,
        //       lastname,
        //       email,
        //       zipcode,
        //       password,
        //     },
        //   },
        // });

      }
      localStorage.setItem("fname", firstname);
      localStorage.setItem("lname", lastname);
      localStorage.setItem("emailID", email);
    })
    return user;
  };
// LOGOUT FUNCTION
  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
